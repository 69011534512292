<template>
  <layout-bottom-btn>
    <template #content v-if="registration">
      <v-container v-if="ready">
        <v-row>
          <v-col align="center" class="mb-0 pb-0">
            <v-avatar size="100">
              <v-img
                size="100"
                class="grey darken-4"
                :src="registration.competitor.avatar"
              />
            </v-avatar>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="center">
            <h1>{{ registration.competitor.name }}</h1>

            <p v-if="registration.competitor.metadata.breed" class="mb-1">
              <small class="grey--text text-uppercase">Ras</small><br>
              {{ registration.competitor.metadata.breed }}
            </p>
            <p v-if="registration.competitor.metadata.breed" class="mb-1">
              <small class="grey--text text-uppercase">Kön</small><br>
              {{ gender(registration.competitor.metadata.gender) }}
            </p>
            <p v-if="registration.competitor.metadata.owner" class="mb-1">
              <small class="grey--text text-uppercase">Ägare / Förare</small><br>
              {{ registration.competitor.metadata.owner.firstname }}
              {{ registration.competitor.metadata.owner.lastname }}
            </p>
            <p v-if="registration.competitor.metadata.chip" class="mb-1">
            <small class="grey--text text-uppercase">Chipnummer</small><br>
            {{ registration.competitor.metadata.chip }}
            </p>
            <p v-if="registration.competitor.metadata.regId" class="mb-1">
            <small class="grey--text text-uppercase">
              Registreringsnummer
            </small><br>
            {{ registration.competitor.metadata.regId }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-container v-if="rounds">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-btn
                    :key="verified"
                    :class="verifiedState"
                    style="width: 100%;"
                    elevation="0"
                    large
                    @click="modal = true"
                  >
                    <span v-if="isVerified">
                      <v-icon
                        class="mr-1">mdi-check
                      </v-icon>  {{ $t('Verified ID') }}
                    </span>
                    <span v-else>
                      {{ $t('Verify ID') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row class="ma-0 pa-0 mt-2">
                <v-col class="ma-0 pa-0">
                  <v-btn
                    :key="isPaid"
                    :class="[ isPaid ? 'green white--text' : 'blue white--text' ]"
                    :disabled="isOnline"
                    @click="togglePaid"
                    style="width: 100%;"
                    elevation="0"
                    large
                  >
                    <span v-if="!isOnline && isPaid">
                      <v-icon
                        class="mr-1">mdi-check
                      </v-icon>  {{ $t('Paid') }}
                    </span>
                    <span v-else-if="isOnline && isPaid">
                      <v-icon
                        class="mr-1">mdi-check
                      </v-icon>  {{ $t('Paid online') }}
                    </span>
                    <span v-else-if="isOnline && !isPaid">
                      {{ $t('Online unpaid') }}
                    </span>
                    <span v-else>
                      {{ $t('Unpaid') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <template v-if="event.type === 'chase'">
                <v-row class="ma-0 pa-0 mt-2">
                  <v-col class="ma-0 pa-0">
                    <v-btn
                      :class="[isVerified ? 'green white--text' : 'grey white--text']"
                      style="width: 100%;"
                      :disabled="!isVerified"
                      elevation="0"
                      large
                      @click="redirect(`/event/${$route.params.eventId}/registration/${$route.params.registrationId}/chase`)"
                    >
                      <span>Inmatning av Drev</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row
                  v-for="(round, number) in rounds"
                  :key="round.id"
                  class="ma-0 pa-0 mt-2"
                >
                  <v-col class="ma-0 pa-0">
                    <round-toggle-btn
                      :verified="isVerified"
                      :event="event"
                      :round="round"
                      :test="test"
                      :roundNumber="number"
                      :timerState="timerState"
                      :elapsed="(number === rounds.length - 1 && currentRound) ? elapsed : 0"
                      large
                      elevation="0"
                    />
                  </v-col>
                </v-row>
              </template>

              <v-row
                v-if="evaluation && evaluation.registration"
                class="ma-0 pa-0"
                style="margin-top: 10px !important;"
              >
                <v-col class="ma-0 pa-0">
                  <v-btn
                    :class="[
                      isVerified ?
                        (evaluationFilled ?
                          'green white--text' :
                          'blue white--text'
                        ) :
                      'grey white--text'
                    ]"
                    style="width: 100%;"
                    :disabled="!isVerified"
                    elevation="0"
                    large
                    @click="redirect(`/event/${$route.params.eventId}/registration/${$route.params.registrationId}/evaluation`)"
                  >
                    <span v-if="evaluationFilled">
                      <v-icon
                        class="mr-1">mdi-check
                      </v-icon>  {{ $t('Dog\'s evaluation') }}
                    </span>
                    <span v-else>
                      {{ $t('Dog\'s evaluation') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <template v-if="evaluation && evaluation.extra">
                <v-row
                  v-for="(extra, idx) in evaluation.extra"
                  :key="`${extra.title}-${idx}`"
                  class="ma-0 pa-0"
                  style="margin-top: 10px !important;"
                >
                  <v-col class="ma-0 pa-0">
                    <v-btn
                      :class="[
                        isVerified ?
                        (evaluationExtraFilled(extra) ? 'green white--text' : 'blue white--text') :
                        'grey white--text'
                      ]"
                      :disabled="!isVerified"
                      style="width: 100%;"
                      elevation="0"
                      large
                      @click="redirect(`/event/${$route.params.eventId}/registration/${$route.params.registrationId}/evaluation/${idx}`)"
                    >
                      <span v-if="evaluationExtraFilled(extra)">
                        <v-icon
                          class="mr-1">mdi-check
                        </v-icon> {{ $t(extra.title) }}
                      </span>
                      <span v-else>
                        {{ $t(extra.title) }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <v-row
                class="ma-0 mt-5 pa-0 pt-5"
                style="border-top: 1px solid #DCDCDC;"
              >
                <v-col class="ma-0 pa-0">
                  <v-btn
                    :class="[
                      isVerified ? currentSyncState : 'grey white--text'
                    ]"
                    :disabled="!isVerified"
                    style="width: 100%;"
                    elevation="0"
                    large
                    @click="synchronize"
                  >
                    <v-icon
                      class="mr-2"
                    >mdi-sync-circle</v-icon> {{ $t('Upload results') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row class="ma-0 px-0">
                <v-col class="ma-0 px-0">
                  <v-btn
                    :class="[
                      (isVerified && isConnected) ? 'blue white--text' : 'grey white--text'
                    ]"
                    :disabled="!isVerified || !isConnected"
                    style="width: 100%;"
                    elevation="0"
                    large
                    target="_blank"
                    :href="viewRegistrationUrl"
                  >
                    <v-icon
                      class="mr-2"
                    >mdi-open-in-new</v-icon> {{ $t('View results') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <loading-progress v-else message="Loading registration" />

      <verification-modal
        v-if="modal"
        :verified="verified"
        :prevComment="verificationComment"
        :registration="registration"
        @onCloseModal="onCloseModal"
      />

      <confirm-send-modal
        v-if="confirmModal"
        :registration="registration"
        @onCloseModal="onCloseConfirm"
      />
    </template>
  </layout-bottom-btn>
</template>

<script>
import RoundToggleBtn from '@/components/registration/round/toggle-btn'
import LoadingProgress from '@/components/loading-progress'
import LayoutBottomBtn from '@/components/layout-bottom-btn'

import { timerState } from '@/components/registration/round/mixin'
import VerificationModal from '@/components/registration/verification-modal'
import ConfirmSendModal from '@/components/registration/confirm-send-modal'

const _ = require('lodash')

export default {
  name: 'RegistrationShow',

  components: {
    LayoutBottomBtn,
    LoadingProgress,
    RoundToggleBtn,
    VerificationModal,
    ConfirmSendModal,
  },

  data: () => ({
    verified: 0,
    elapsed: 0,
    timerState: 'timer.stopped',
    modal: false,
    confirmModal: false
  }),

  computed: {
    currentSyncState () {
      return this.appState.synchronized ? 'green white--text' : 'blue white--text'
    },

    isConnected () {
      return this.appState.isConnected
    },

    verifiedState () {
      if (!this.verified) {
        return 'blue white--text'
      }

      return this.verified !== 2 ? 'green white--text' :  'orange white--text'
    },

    verificationComment () {
      if (this.registration.results) {

        const result = this.registration.results.find(
          (r) => r.type === 'validation_comment'
        )

        if (result) {
          return result.comment
        }
      }
      return ''
    },

    registration () {
      return this.$store.state.rx.registrations.find(
        (e) => e.id === this.$route.params.registrationId
      )
    },

    event () {
      return this.$store.state.rx.events.find(
        (e) => e.id === this.$route.params.eventId
      )
    },

    currentRound () {
      return _.last(this.registration.rounds)
    },

    test () {
      return this.$store.state.rx.tests.find(
        t => t.type === this.event.type
      )
    },

    values() {
      const categories = this.event.categories.map((c) => ({
        type: `category_${c.type.toLowerCase()}`,
        value_text: c.value,
      }))

      return [
        ...this.registration.results,
        ...categories
      ]
    },

    evaluation () {
      return this.test.evaluation
    },

    evaluationFilled () {
      const evaluations = _.uniqBy(this.$nosework.getEvaluations(this.evaluation.registration, {
        onlyValidation: true,
      }), (regx) => regx.source)

      const results = this.registration.results.filter(
        r => evaluations.some(e => e.test(r.type))
      )

      const check = _.sum(results.map(
        r => evaluations.some(e => e.test(r.type)) ? 0 : 1
      ))

      return check === 0 && results.length >= evaluations.length
    },

    rounds () {
      return this.registration.rounds
    },

    ready () {
      return !!this.registration && !!this.rounds && !!this.test
    },

    isVerified () {
      return this.verified > 0
    },

    isOnline () {
      if (!this.registration) {
        return false;
      }

      return this.registration.payment.onlinePayment;
    },

    isPaid () {
      if (!this.registration) {
        return false;
      }
      return this.registration.payment.paid;
    },

    dogInfo () {
      const { metadata } = this.registration.competitor
      const info = [
        this.$t(metadata.breed),

      ]
      const owner = _.get(metadata, 'owner', {})

      Object.keys(owner).forEach((prop) => {
        info.push(owner[prop])
      })

      return info.join(' / ')
    },

    viewRegistrationUrl() {
      return `${this.$nosework.smoothcomp.baseWebUrl}/sv/skk/registrations/${this.registration.id}`
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (!this.event) {
        this.$router.push('/event').catch(() => {})
      } else if (!this.registration) {
        this.$router.push(`/event/${this.$route.params.eventId}`).catch(() => {})
      }

      this.$store.commit('setNavigation', {
        show: true,
        to: `/event/${this.$route.params.eventId}`,
        title: this.$t(_.startCase(
          this.event.type ? this.event.type : 'unknown'
        ))
      })
    })

    this.lastTick = this.$nosework.timestamp
    this.timestamp = this.$nosework.timestamp

    this.$nosework.watch('timestamp', (value) => {
      if (
        this.timerState === timerState.running &&
        this.timestamp > 0 &&
        value > 0
      ) {
        this.elapsed += (value - this.timestamp)
        this.totaltime += (value - this.timestamp)
      }

      this.timestamp = value
    })

    const validation = this.registration.results.find((r) => r.type === 'validation_comment')
    if (validation) {
      this.verified = validation.value
    }

    this.checkLastStep()
  },

  methods: {
    async togglePaid() {
      if (this.isOnline) {
          return;
      }

      await this.registration.atomicUpdate((reg) => {
        reg.payment.paid = !this.isPaid;
        return reg
      })
    },

    async onCloseConfirm(data) {
      if (data === 1) {
        await this.registration.atomicUpdate((reg) => {
          reg.status = 'completed'
          return reg
        })
      }

      this.confirmModal = false
    },

    async onCloseModal (data) {
      if (data && data.state) {
        this.verified = data.state;

        try {
          await this.registration.atomicUpdate((reg) => {
            const result = {
              type: 'validation_comment',
              comment: data.comment,
              value: this.verified,
              value_text: 'Verified ID'
            }

            if (reg.results) {
              const idx = reg.results.findIndex((r) => r.type === 'validation_comment')

              if (idx > -1) {
                reg.results[idx] = result
              } else {
                reg.results.push(result)
              }
            } else {
              reg.results = [result]
            }

            return reg
          })
        } catch (err) {
          console.log(err)
        }
      }

      this.modal = false
    },

    evaluationExtraFilled(evaluation) {
      const evaluations = this.$nosework.getEvaluations([evaluation], {
        checkRules: this.values,
      })

      const results = this.registration.results.filter(
        r => evaluations.some(e => e.test(r.type))
      )

      const check = _.sum(results.map(
        r => evaluations.some(e => e.test(r.type)) ? 0 : 1
      ))

      return check === 0 && results.length >= evaluations.length
    },

    gender (gender) {
      return this.$t(gender === 'F' ? 'Female' : 'Male')
    },

    synchronize () {
      this.appState.showSyncWrapper = true
    },

    async checkLastStep () {
      const round = this.currentRound
      if (!this.currentRound) {
        return
      }

      const last = _.last(this.currentRound.steps)

      if (last) {
        if (last.state === 'stop' || last.state === 'abort') {
          this.elapsed = last.elapsed
          this.timerState = 'timer.closed'

          if (this.registration.rounds.length < this.test.max_rounds) {
            await this.registration.atomicUpdate((reg) => {
              reg.rounds.push({
                results: [],
                steps: [],
              });

              return reg
            })
          }

          return
        }

        if (last.state === 'pause') {
          this.elapsed = last.elapsed
        } else {
          this.elapsed = last.elapsed + (this.timestamp - last.timestamp)
        }

        const steps = this.test.steps.map(s => s.state)
        const rsteps = _.reverse(_.cloneDeep(round.steps))
        const lastPause = rsteps.findIndex(s => s.state === 'pause')
        const lastResume = rsteps.findIndex(
          s => s.state === 'restart' || steps.includes(s.state)
        )

        this.timerState = (lastResume > lastPause && lastPause !== -1) ? 'timer.paused' : 'timer.running'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .v-avatar {
    img {
      object-fit: cover;
      object-position: center center;
    }
  }
</style>
