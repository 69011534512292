<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">
        <slot name="title">
          {{ $t('Send results') }}
        </slot>
      </v-card-title>

      <v-card-text class="mb-0 pb-0">
        <slot name="text">
          Vill du skicka in ditt reslutat för {{ registration.competitor.name }} till SKK Start?<br><br>
          <b>OBS - Provet försvinner från appen efter att resultatet är inskickat och hanteras därefter på skkstart.se</b>
        </slot>
      </v-card-text>

      <v-card-actions class="pa-4 white">
        <v-btn
          elevation="0"
          right
          text
          class="grey white--text"
          xLarge
          @click="onClose(0)"
        >
          {{ $t('No') }}
        </v-btn>

        <v-spacer />

        <v-btn
          right
          text
          class="blue white--text"
          xLarge
          @click="onClose(1)"
        >
          {{ $t('Yes') }}, {{ $t('Send results') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',

  props: {
    registration: {
      type: Object,
      default: () => {}
    },
  },

  data: () => ({
    dialog: true,
  }),

  methods: {
    onClose (state) {
      this.$emit('onCloseModal', state)
    }
  }
}
</script>
