<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">
        <slot name="title">
          {{ $t('Verify ID') }}
        </slot>
      </v-card-title>

      <v-card-text class="mb-0 pb-0">
        <slot name="text">
          <v-row class="ma-0 pa-0 body-1">
            <v-col align="center">
              <p v-if="registration.competitor.metadata.chip" class="mb-1 black--text">
              <small class="grey--text text-uppercase">Chipnummer</small><br>
              {{ registration.competitor.metadata.chip }}
              </p>
              <p v-if="registration.competitor.metadata.regId" class="mb-1 black--text">
              <small class="grey--text text-uppercase">
                Registreringsnummer
              </small><br>
              {{ registration.competitor.metadata.regId }}
              </p>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>

      <v-card-actions style="display: block" class="mt-0 pt-0">
        <v-row class="ma-3 mt-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-list-item-content>
              <v-divider
                class="mt-3"
                v-if="commentInvalid"
              />

              <v-btn
                class="green white--text mt-3"
                :large="$vuetify.breakpoint.name !== 'xs'"
                elevation=0
                @click="onClose(1)"
              >
                {{ $t('ID Ok') }}
              </v-btn>

              <v-btn
                v-if="!commentInvalid"
                class="orange white--text mt-3"
                :large="$vuetify.breakpoint.name !== 'xs'"
                elevation=0
                @click="invalid()"
              >
                {{ $t('ID Invalid') }}
              </v-btn>

              <v-divider
                class="mt-3"
                v-if="commentInvalid"
              />

              <h3
                v-if="commentInvalid"
                class="mt-3"
              >{{ $t('ID Invalid') }}</h3>

              <small
                v-if="commentInvalid"
                class="mt-3 grey--text"
              >
                {{ $t('Invalid ID description') }}
              </small>

              <v-textarea
                v-if="commentInvalid"
                v-model="comment"
                outlined
                class="mt-3"
                :placeholder="$t('Comment')"
                :hideDetails="true"
              />

              <v-btn
                v-if="commentInvalid"
                :disabled="comment.trim().length === 0"
                :large="$vuetify.breakpoint.name !== 'xs'"
                class="blue white--text mt-3"
                elevation=0
                @click="onClose(2)"
              >
                {{ $t('Save') }}
              </v-btn>

               <v-btn
                 elevation="0"
                 class="default mt-3"
                 color="light-grey"
                 text
                 :large="$vuetify.breakpoint.name !== 'xs'"
                 @click="onClose()"
                 >
                 {{ $t('Abort') }}
               </v-btn>
             </v-list-item-content>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertModal',

  props: {
    registration: {
      type: Object,
      default: () => {}
    },

    prevComment: {
      type: String,
      default: ''
    },

    verified: {
      type: Number,
      default: 0
    },
  },

  data: () => ({
    dialog: true,
    commentInvalid: false,
    comment: ''
  }),

  mounted () {
    if (this.verified === 2) {
      this.commentInvalid = true
      this.comment = this.prevComment
    }
  },

  methods: {
    invalid () {
      this.commentInvalid = true
    },

    onClose (state) {
      if (!this.commentInvalid) {
        this.comment = ''
      }

      this.$emit('onCloseModal', {
        state,
        comment: this.comment
      })
    }
  }
}
</script>
