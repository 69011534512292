<template>
  <v-btn
    v-if="round.steps"
    elevation="0"
    :large="$vuetify.breakpoint.name !== 'xs'"
    style="width: 100%;"
    :class="btnClass"
    :disabled="!verified"
    @click="redirect(`/event/${$route.params.eventId}/registration/${$route.params.registrationId}/round/${roundNumber}`)"
  >
    <span v-if="aborted || (complete && evaluated)">
      <v-icon
        class="mr-1">mdi-check
      </v-icon>  {{ $t(btnTxt) }}
    </span>
    <span v-else>
      {{ $t(btnTxt) }}

      <span v-if="elapsed && timerState && timerState === 'timer.running'">
        - {{ $t(getTimerState) }}<span :inner-html.prop="elapsed | durationSemicolons" />
      </span>
    </span>

  </v-btn>
</template>

<script>

import { timerState } from '@/components/registration/round/mixin'

// import 'dayjs/locale/sv'
// const dayjs = require('dayjs')

export default {
  name: 'RoundToggleBtn',

  props: {
    event: {
      type: Object,
      required: true
    },

    round: {
      type: Object,
      required: true
    },

    test: {
      type: Object,
      required: true
    },

    timerState: {
      type: String,
      required: false,
      default: 'timer.stopped'
    },

    elapsed: {
      type: Number,
      required: false,
      default: 0
    },

    roundNumber: {
      type: Number,
      required: true
    },

    verified: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    complete () {
      if (!this.round.steps || this.round.steps.length === 0) {
        return false
      }

      return this.round.steps.filter(
        (s) => this.test.steps.find((t) => t.state === s.state)
      ).length === this.test.steps.length
    },

    aborted() {
      return this.round.steps.find((s) => s.state === 'abort') !== undefined
    },

    evaluated () {
      if (!this.complete) {
        return false
      }

      const primary = this.test.steps.map((t) => {
        if (!t.evaluation) {
          return true
        }

        const step = this.round.steps.find((s) => s.state === t.state)
        if (!step || !step.results) {
          return null
        }

        return step.results.length >= t.evaluation.inputs.length
      }).filter((t) => t).length === this.test.steps.length

      if(!this.test.additional_steps) {
        return primary;
      }

      const additionalSteps = this.test.additional_steps.filter(t => t.scope);

      const additional = additionalSteps.map((t) => {
        const result = this.round.results.find(s => s.type === t.scope)
        if (!result) {
          return null
        }

        return true
      }).filter(t => t).length === additionalSteps.length

      return (primary && additional)
    },

    getTimerState() {
      switch(this.timerState) {
        case timerState.paused:
          return `${this.$t('Paused')} `
        case timerState.running:
          return `${this.$t('Running')} `
        default:
          return ''
      }
    },

    btnClass () {
      if (this.aborted) {
        return 'green white--text'
      }

      if (!this.verified) {
        return 'grey white--text'
      }

      if (this.complete && this.evaluated) {
        return 'green white--text'
      }

      if (this.complete) {
        return 'amber white--text'
      }

      return 'blue white--text'
    },

    btnTxt () {
      const round = `${this.$t('Round')} ${this.roundNumber + 1}`

      if (this.round.steps && this.round.steps.length > 0) {
        if (this.aborted && this.test.max_rounds === 1) {
          return `${this.$t('Aborted')} ${this.$t('track')}`
        }

        if (this.aborted) {
          return `${round} (${this.$t('Aborted')})`
        }

        if (this.complete && this.test.max_rounds === 1) {
          return this.$t('View track results')
        }

        if (this.complete) {
          return `${round} (${this.$t('View track results')})`
        }

        if (this.test.max_rounds === 1) {
          return `${this.$t('Open')} ${this.$t('track')}`
        }

        return `${round} (${this.$t('Open')})`
      }

      if (this.test.max_rounds === 1) {
        return `${this.$t('Start')} ${this.$t('track')}`
      }

      return `${round} (${this.$t('Start')})`
    }
  }
}
</script>
